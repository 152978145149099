
const state = {
    select: false,
    selectList: []
}

const mutations = {
    changeSelect(state, flag) {
        if (!flag) {
            state.selectList = []
        }
        state.select = flag
    },
    changeSelectList(state, obj) {
        if (obj.flag) {
            state.selectList.push(obj.inquirerId)
        } else {
            state.selectList = state.selectList.filter(item => item != obj.inquirerId)
        }
    },
    resetState(state) {
        state.select = false
        state.selectList = []
    }
}

const actions = {

}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}