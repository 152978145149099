import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import taikangRouter from './taikang'
import followupRouter from './followup'
import intelligentMarketingRouter from './intelligentMarketing'
import qzServiceCenterRouter from './quezhenServiceCenter'
import scaleRouter from './scale'
import recheckRemindRouter from './recheck-remind'
import ctcaeRouter from './ctcae'
import medicalRecordRouter from './medical-record'
import emotionRouter from './emotion-chat'
import doctorAppRouter from './doctor-app'
import patientJourneyRouter from './patient-journey'
import clinicalTrialRouter from './clinical-trial'
import points from './points'
import reportInterpret from './report-interpret'



Vue.use(VueRouter)

const routes = [{
  path: '/diseaseIndex',
  name: 'diseaseIndex',
  meta: {
    title: "疾病管理方案"
  },
  component: () =>
    import('@/views/disease/diseaseIndex'),
},
{
  path: '/diseaseProblem',
  name: 'diseaseProblem',
  meta: {
    title: "疾病管理方案"
  },
  component: () =>
    import('@/views/disease/diseaseProblem'),
},
{
  path: '/disease',
  name: 'disease2',
  meta: {
    title: "疾病管理方案"
  },
  component: () =>
    import('@/views/disease/disease'),
},
{
  path: '/healthRecords/:chapters*',
  // path: '/healthRecords',
  name: 'healthRecords',
  meta: {
    title: "智能病历夹"
  },
  component: () =>
    import('@/views/healthRecords/index'),
},
{
  path: '/upload',
  name: 'upload',
  meta: {
    title: "上传报告"
  },
  component: () =>
    // import('@/views/healthRecords/upload2'),
    // 使用统一的上传页面 2023.09.05 guoyijie
    import('@/views/RecheckRemind/RecheckRemindPatient/RecheckRemindUploadPage/RecheckRemindUploadPage')
},
//费用预估
{
  path: '/costIndex',
  name: 'costIndex',
  meta: {
    title: "预估治疗方案与费用"
  },
  component: () =>
    import('@/views/costEstimate/costIndex'),
},
{
  path: '/costInfo',
  name: 'costInfo',
  meta: {
    title: "预估治疗方案与费用"
  },
  component: () =>
    import('@/views/costEstimate/costInfo'),
},
{
  path: '/costProblem',
  name: 'costProblem',
  meta: {
    title: "预估治疗方案与费用"
  },
  component: () =>
    import('@/views/costEstimate/costProblem'),
},
{
  path: '/costKnowledge',
  name: 'costKnowledge',
  meta: {
    title: "预估治疗方案与费用"
  },
  component: () =>
    import('@/views/costEstimate/costKnowledge'),
},
{
  path: '/videoLibrary',
  name: 'videoLibrary',
  meta: {
    title: "视频库"
  },
  component: () =>
    import('@/views/videoLibrary/videoLibrary'),
},
{
  path: '/videoLibraryAll',//这个页面是用在公众号中的使用说明的页面
  name: 'videoLibraryAll',//这个页面是用在公众号中的使用说明的页面
  meta: {
    title: "使用说明"
  },
  component: () =>
    import('@/views/videoLibrary/videoLibraryAll'),
},
{
  path: '/doctorVideoLibrary',
  name: 'doctorVideoLibrary',
  meta: {
    title: "视频库"
  },
  component: () =>
    import('@/views/videoLibrary/doctorVideoLibrary'),
},
{
  path: '/yuanxinVideo',
  name: 'yuanxinVideo',
  meta: {
    title: "智疗医生"
  },
  component: () =>
    import('@/views/yuanxinVideo/index'),
},
...taikangRouter,
...followupRouter,
...intelligentMarketingRouter,
{
  path: '/patientRecordApp',
  name: 'patientRecordApp',
  meta: {
    title: "智能病历夹"
  },
  component: () =>
    import('@/views/healthRecords/patientRecordAppPage/patientRecordAppPage'),
},
...qzServiceCenterRouter,
...ctcaeRouter,
...scaleRouter,
...recheckRemindRouter,
...medicalRecordRouter,
...emotionRouter,
...doctorAppRouter,
...patientJourneyRouter,
...clinicalTrialRouter,
...reportInterpret,
...points
  // {
  //     path: '/',
  //     name: 'login',
  //     component: () => import('@/views/followup/followup-doctor/qrcodePage/qrcodePage'),
  //     // hidden: true,
  // },
]

const router = new VueRouter({
  routes,
})

router.beforeEach(async (to, from, next) => {
  if (to.query.session) {
    sessionStorage.setItem('session', to.query.session);
  }
  if (to.query.ctcaeSession) {
    sessionStorage.setItem('ctcae-session', to.query.ctcaeSession);
  }
  if (to.query.token) {
    sessionStorage.setItem('token', to.query.token);
  }
  if (to.query.externalToken) {
    sessionStorage.setItem('token', to.query.externalToken);
  }
  if (to.query.marketingToken) {
    sessionStorage.setItem('token', to.query.marketingToken);
  }
  if (to.query.qztoken) {
    sessionStorage.setItem('qztoken', to.query.qztoken);
  }
  if (to.query.patientInfoId) {
    sessionStorage.setItem('patientInfoId', to.query.patientInfoId);
  }
  if (to.query.requestToken) {
    sessionStorage.setItem('requestToken', to.query.requestToken);
  }
  if (to.query.Authorization) {
    sessionStorage.setItem('Authorization', to.query.Authorization);
  }
  // 由于项目不同，就诊人ID的命名也不同，inquirerId和patientInfoId都是就诊人ID
  if (to.query.patientInquirerId) {
    sessionStorage.setItem('inquirerId', to.query.patientInquirerId);
  }
  if (to.query.inquirerId) {
    sessionStorage.setItem('inquirerId', to.query.inquirerId);
  }
  if (to.query.aiProductUserId) {
    sessionStorage.setItem('aiProductUserId', to.query.aiProductUserId);
  }


  document.title = to.meta.title
  next()
})

export default router