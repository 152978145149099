// 患者旅程Router
// Created by guoyijie on 2024-02-20.


// https://marketing-dev.hospital.foryor-in.com/#/journeypaitentlist?qztoken=20216eec1591cef0ff28&doctorId=272

const patientJourneyRouter = [
  {
    path: '/myjourneycalendar',
    name: 'my-journey-calendar',
    meta: {
      title: "康复之旅"
    },
    component: () =>
      import('@/views/patientJourney/patientJourneyPatient/myJourneyCanlenderPage/myJourneyCanlenderPage.vue'),
  },

  // ----------------------------------------- //
  {
    path: '/addjourneytemplate',
    name: 'add-journey-template',
    meta: {
      title: "新增模版"
    },
    component: () =>
      import('@/views/patientJourney/patientJourneyDoctor/addJourneyTemplatePage/addJourneyTemplatePage.vue'),
  },
  {
    path: '/journeytemplatelist',
    name: 'journey-template-list',
    meta: {
      title: "模版列表"
    },
    component: () =>
      import('@/views/patientJourney/patientJourneyDoctor/journeyTemplateListPage/journeyTemplateListPage.vue'),
  },
  {
    path: '/journeypaitentlist',
    name: 'journey-paitent-list',
    meta: {
      title: "患者列表"
    },
    component: () =>
      import('@/views/patientJourney/patientJourneyDoctor/patientListPage/patientListPage.vue'),
  },
  {
    path: '/patientjourneymain',
    name: 'patient-journey-main',
    meta: {
      title: "患者旅程"
    },
    component: () =>
      import('@/views/patientJourney/patientJourneyDoctor/patientJourneyMainPage/patientJourneyMainPage.vue'),
  },
  {
    path: '/scaledetail',
    name: 'scale-detail',
    meta: {
      title: "量表详情"
    },
    component: () =>
      import('@/views/patientJourney/patientJourneyDoctor/scaleDetailPage/scaleDetailPage.vue'),
  },
  {
    path: '/journeyarticledetail',
    name: 'journey-article-detail',
    meta: {
      title: "文章详情"
    },
    component: () =>
      import('@/views/patientJourney/patientJourneyDoctor/articleDetailPage/articleDetailPage.vue'),
  },
  {
    path: '/journeyvideodetail',
    name: 'journey-video-detail',
    meta: {
      title: "视频详情"
    },
    component: () =>
      import('@/views/patientJourney/patientJourneyDoctor/videoDetailPage/videoDetailPage.vue'),
  },

]


export default patientJourneyRouter


