// 血栓测评Router
// Created by guoyijie on 2023-05-11.
const scaleRouter = [
  {
    path: '/scaleindex',
    name: 'scale-index',
    meta: {
      title: "我的量表"
    },
    component: () =>
      import('@/views/scale/ScaleIndex'),
  },
  {
    path: '/scalequestion',
    name:'scale-question',
    meta: {
      title: "量表答题"
    },
    component: () =>
      import('@/views/scale/ScaleQuestion'),
  },
  {
    path: '/scaleresult',
    name:'scale-result',
    meta: {
      title: "量表结果"
    },
    component: () =>
      import('@/views/scale/ScaleHistoryDetail'),
  },
  {
    path: '/scalehistorylist',
    name:'scale-history-list',
    meta: {
      title: "量表历史"
    },
    component: () =>
      import('@/views/scale/ScaleHistoryList'),
  },
  {
    path: '/scalehistorydetail',
    name:'scale-history-detail',
    meta: {
      title: "量表结果详情"
    },
    component: () =>
      import('@/views/scale/ScaleHistoryDetail'),
  },
  {
    path: '/crtquestion',
    name: 'crt-question',
    meta: {
      title: "CRT风险预测模型"
    },
    component: () =>
      import('@/views/scale/crt-prediction/CrtQuestion'),
  },
  {
    path: '/crtresult',
    name: 'crt-result',
    meta: {
      title: "CRT风险预测模型"
    },
    component: () =>
      import('@/views/scale/crt-prediction/CrtResult'),
  },
  {
    path: '/crtresultlist',
    name: 'crt-result-list',
    meta: {
      title: "CRT风险预测模型"
    },
    component: () =>
      import('@/views/scale/crt-prediction/CrtResultList'),
  },
  {
    path: '/crtpatientlist',
    name: 'crt-patient-list',
    meta: {
      title: "患者列表"
    },
    component: () =>
      import('@/views/scale/crt-prediction/CrtPatientList'),
  }
]


export default scaleRouter


