// 医生端APP中使用的，与小飞侠相关的页面

const doctorAppRouter = [
  {
    path: '/doctorappuploadpatientlist',
    name: 'doctor-app-upload-patient-list',
    meta: {
      title: "患者列表"
    },
    component: () =>
      import('@/views/doctorApp/uploadPatientListPage/patientListPage'),
  },
  {
    path: '/doctorappdoctorlist',
    name: 'doctor-app-doctor-list',
    meta: {
      // TODO-T 临时修改
      // title: "医护列表"
      // ----------
      title: "医生列表"
    },
    component: () =>
      import('@/views/doctorApp/doctorListPage/doctorListPage'),
  },
  {
    path: '/doctorappframeworkpatientlist',
    name: 'doctor-app-framework-patient-list',
    meta: {
      title: "患者列表"
    },
    component: () =>
      import('@/views/doctorApp/frameworkPatientListPage/frameworkPatientListPage'),
  },
  {
    path: '/doctorappupload',
    name: 'doctor-app-upload',
    meta: {
      title: "上传病历"
    },
    component: () =>
      import('@/views/doctorApp/uploadPage/uploadPage'),
  },
  {
    path: '/bookingbedcalendar',
    name: 'booking-bed-calendar',
    meta: {
      title: "预约住院"
    },
    component: () =>
      import('@/views/doctorApp/bookingBed/bookingBedCalendarPage/bookingBedCalendarPage'),
  },
  {
    path: '/bookingbedpatientlist',
    name: 'booking-bed-patient-list',
    meta: {
      title: "预约住院"
    },
    component: () =>
      import('@/views/doctorApp/bookingBed/bookingBedPaitentListPage/bookingBedPaitentListPage'),
  },
  {
    path: '/allfunctionpatientlist',
    name: 'all-funtion-patient-list',
    meta: {
      title: "患者列表"
    },
    component: () =>
      import('@/views/doctorApp/allFunctionPatientListPage/allFunctionPatientListPage'),
  },
  {
    path: '/allfunctionpatientmain',
    name: 'all-function-patient-main',
    meta: {
      title: "患者详情"
    },
    component: () =>
      import('@/views/doctorApp/allFunctionPatientMainPage/allFunctionPatientMainPage'),
  },
  {
    path: '/doctorReportinterpret',
    name: 'doctorReportinterpret',
    meta: {
      title: "报告结果解读"
    },
    component: () =>
      import('@/views/doctorApp/allFunctionPatientMainPage/doctorReportinterpret'),
  },
  {
    path: '/doctorReportinterpretDetail',
    name: 'doctorReportinterpretDetail',
    meta: {
      title: "报告结果解读"
    },
    component: () =>
      import('@/views/doctorApp/allFunctionPatientMainPage/doctorReportinterpretDetail'),
  },
  {
    path: '/nursehighriskpatientlist',
    name: 'nurse-high-risk-patient-list',
    meta: {
      title: "高风险患者列表"
    },
    component: () =>
      import('@/views/doctorApp/nurseHighRiskPatientListPage/nurseHighRiskPatientListPage'),
  },
  {
    path: '/nurseReportinterpret',
    name: 'nurseReportinterpret',
    meta: {
      title: "报告结果解读"
    },
    component: () =>
      import('@/views/doctorApp/nurseHighRiskPatientListPage/nurseReportinterpret'),
  },
  {
    path: '/doctorpersonalinfo',
    name: 'doctor-personal-info',
    meta: {
      title: "基本信息"
    },
    component: () =>
      import('@/views/doctorApp/doctorPersonalInfoPage/doctorPersonalInfoPage'),
  },


]

export default doctorAppRouter