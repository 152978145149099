// 报告解读Router
// Created by guoyijie on 2024-10-10.

const pointsRouter = [
    {
      path: '/points',
      name: 'points',
      component: () =>
        import('@/views/points/pointsIndex'),
      meta: {
        title: '积分获取',
      }
    },
    {
      path: '/pointsRanking',
      name: 'pointsRanking',
      component: () =>
        import('@/views/points/pointsRanking'),
      meta: {
        title: '积分排行榜',
      }
    },
    {
      path: '/doctorRanking',
      name: 'doctorRanking',
      component: () =>
        import('@/views/points/doctorRanking'),
      meta: {
        title: '积分排行',
      }
    },
    {
      path: '/awardDetail',
      name: 'awardDetail',
      component: () =>
        import('@/views/points/awardDetail'),
      meta: {
        title: '奖品内容',
      }
    },
    {
      path: '/myAward',
      name: 'myAward',
      component: () =>
        import('@/views/points/myAward'),
      meta: {
        title: '我的奖品',
      }
    },
    {
      path: '/commentList',
      name: 'commentList',
      component: () =>
        import('@/views/points/commentList'),
      meta: {
        title: '评论',
      }
    },
    {
      path: '/awardList',
      name: 'awardList',
      component: () =>
        import('@/views/points/awardList'),
      meta: {
        title: '奖品列表',
      }
    },
    
    
  ]
  
  export default pointsRouter