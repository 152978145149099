import Cookies from 'js-cookie'

// const TokenKey = 'USER-TOKEN'

//cookie
export function getCookie(Key) {
  return JSON.parse(Cookies.get(Key) || JSON.stringify(''))
}

export function setCookie(Key, token) {
  return Cookies.set(Key, JSON.stringify(token))
}

export function removeCookie(Key) {
  return Cookies.remove(Key)
}

//路由标题
const title = '智疗医生-患者病历结构化后台'
export function getPageTitle(pageTitle) {
  if (pageTitle) {
    return `${pageTitle} - ${title}`
  }
  return `${title}`
}
//实现递归深拷贝
export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'deepClone')
  }
  const targetObj = source.constructor === Array ? [] : {}
  Object.keys(source).forEach((keys) => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys])
    } else {
      targetObj[keys] = source[keys]
    }
  })
  return targetObj
}

//formData
export function formData(query) {
  let form_data = new FormData()
  for (let i = 0; i < Object.keys(query).length; i++) {
    if (Object.values(query)[i] instanceof Array) {
      for (let k = 0; k < Object.values(query)[i].length; k++) {
        // form_data.append(Object.keys(query)[i] + '[' + k + ']', Object.values(query)[i][k]);
        form_data.append(Object.keys(query)[i], Object.values(query)[i][k])
      }
    } else {
      form_data.append(Object.keys(query)[i], Object.values(query)[i])
    }
  }
  return form_data
}

export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

// 生成uuid
export function getUUID() {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		var r = Math.random() * 16 | 0,
			v = c == 'x' ? r : (r & 0x3 | 0x8);
		return v.toString(16);
	});
}

// 输出 yyyy-MM-dd 格式的日期
export function dateFormatter(date) {
  if (date) {
    let month = date.getMonth() + 1
    if (month < 10) {
      month = '0' + month
    }
    let day = date.getDate()
    if (day < 10) {
      day = '0' + day
    }
    return `${date.getFullYear()}-${month}-${day}`
  } else {
    return ''
  }
}

export const addMonth = (date, month) => {
  if (!(date instanceof Date) || isNaN(date)) {
    return
  }
  if (!(Number.isInteger(month)) || isNaN(month)) {
    return
  }

  let b = [1,3,5,7,8,10,12]
  let y = date.getFullYear();
  let m = date.getMonth();
  let d = date.getUTCDate();
  let newY, newM, newD
  newY = y;
  newM = m + month;
  if (newM > 12) {
    let count = Number.parseInt(newM / 12)
    newY = y + count
    newM = (newM % 12)
  }
  newD = d

  if (b.indexOf(newM + 1) != -1) {
    newD = 31
  }else {
    if (newM == 2) {
      if ((newY % 4) == 0) {
        newD = 29
      }else {
        newD = 28
      }
    }else {
      newD = 30
    }
  }
  return new Date(newY, newM, newD);
}

export const addDay = (date, number) => {
  let newDate = new Date();
  newDate = new Date(newDate.setDate(newDate.getUTCDate() + number))
  return newDate
}

function utctimestamp() {
  let date = new Date()
  let YYYY = date.getUTCFullYear()
  let MM = padding2(date.getUTCMonth() + 1)
  let DD = padding2(date.getUTCDate())
  let HH = padding2(date.getUTCHours())
  let mm = padding2(date.getUTCMinutes())
  let ss = padding2(date.getUTCSeconds())
  return `${YYYY}-${MM}-${DD}T${HH}:${mm}:${ss}Z`
}
