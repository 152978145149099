// 复查提醒
// Created by guoyijie on 2023-05-05.
const recheckRemindRouter = [
  {
    path: '/recheckquestionnairelist',
    name: 'recheck-questionnaire-list',
    meta: {
      title: "问卷列表"
    },
    component: () =>
      import('@/views/RecheckRemind/RecheckRemindPatient/QuestionnaireListPage/QuestionnaireListPage'),
  },
  {
    path: '/recheckriskquestionnaire',
    name: 'recheck-risk-questionnaire',
    meta: {
      title: "不良反应问卷"
    },
    component: () =>
      import('@/views/RecheckRemind/RecheckRemindPatient/RiskQuestionnairePage/RiskQuestionnairePage'),
  },
  {
    path: '/recheckreminddetail',
    name: 'recheck-remind-detail',
    meta: {
      title: "复查用药详情"
    },
    component: () =>
      import('@/views/RecheckRemind/RecheckRemindPatient/RecheckRemindDetailPage/RecheckRemindDetailPage'),
  },
  {
    path: '/recheckremindupload',
    name: 'recheck-remind-upload',
    meta: {
      title: "上传病历"
    },
    component: () =>
      import('@/views/RecheckRemind/RecheckRemindPatient/RecheckRemindUploadPage/RecheckRemindUploadPage'),
  },
  {
    path: '/medicalremindsetting',
    name: 'medical-remind-setting',
    meta: {
      title: "提醒设置"
    },
    component: () =>
      import('@/views/RecheckRemind/RecheckRemindPatient/MedicalRemindSettingPage/MedicalRemindSettingPage'),
  },
  {
    path: '/articledetail',
    name: 'article-detail',
    meta: {
      title: ""
    },
    component: () =>
      import('@/views/RecheckRemind/RecheckRemindPatient/ArticleDetailPage/ArticleDetailPage'),
  },
  {
    path: '/verifyrecheckplan',
    name: 'verify-recheck-plan',
    meta: {
      title: "审核复查计划"
    },
    component: () =>
      import('@/views/RecheckRemind/RecheckRemindPatient/VerifyRecheckPlanPage/VerifyRecheckPlanPage'),
  },
  {
    path: '/editrecheckplan',
    name: 'edit-recheck-plan',
    meta: {
      title: "编辑复查计划"
    },
    component: () =>
      import('@/views/RecheckRemind/RecheckRemindPatient/EditRecheckPlanPage/EditRecheckPlanPage'),
  },
  {
    path: '/selectinspect',
    name: 'select-inspect',
    meta: {
      title: "选择检查项目"
    },
    component: () =>
      import('@/views/RecheckRemind/RecheckRemindPatient/SelectInspectPage/SelectInspectPage'),
  },
  {
    path: '/supplyinspect',
    name: 'supply-inspect',
    meta: {
      title: "补充检查项目"
    },
    component: () =>
      import('@/views/RecheckRemind/RecheckRemindPatient/SupplyInspectPage/SupplyInspectPage'),
  },
  {
    path: '/verifyrecheckrecord',
    name: 'verify-recheck-record',
    meta: {
      title: "审核随访记录"
    },
    component: () =>
      import('@/views/RecheckRemind/RecheckRemindPatient/VerifyRecheckRecordPage/VerifyRecheckRecordPage'),
  },
  {
    path: '/medicalclockin',
    name: 'medical-clock-in',
    meta: {
      title: "用药打卡"
    },
    component: () =>
      import('@/views/RecheckRemind/RecheckRemindPatient/MedicalClockInPage/MedicalClockInPage'),
  },
  {
    path: '/medicalmanage',
    name: 'medical-manage',
    meta: {
      title: "用药列表"
    },
    component: () =>
      import('@/views/RecheckRemind/RecheckRemindPatient/MedicalManagePage/MedicalManagePage'),
  },
  {
    path: '/followupRemindSetting',
    name: 'recheck-remind-setting',
    meta: {
      title: "提醒设置"
    },
    component: () =>
      import('@/views/RecheckRemind/RecheckRemindPatient/RecheckRemindSettingPage/RecheckRemindSettingPage'),
  },
  {
    path: '/completeclockin',
    name: 'complete-clockin',
    meta: {
      title: "全部待补卡"
    },
    component: () =>
      import('@/views/RecheckRemind/RecheckRemindPatient/MedicalCompleteClockInPage/MedicalCompleteClockInPage'),
  },
  {
    path: '/medicalguide',
    name: 'medical-guide',
    component: () =>
      import('@/views/RecheckRemind/RecheckRemindPatient/MedicalGuide/medicalGuide'),
    meta: {
      title: '用药指引',

    }
  },
  // -------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------
  // 下面是医生端的路径-----------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------

  {
    path: '/qrcode',
    name: 'qrcode',
    meta: {
      title: "二维码名片"
    },
    component: () =>
      import('@/views/RecheckRemind/RecheckRemindDoctor/qrcodePage/qrcodePage'),
  },

  {
    path: '/articleCenter',
    name: 'article-center',
    meta: {
      title: "患教中心"
    },
    component: () =>
      import('@/views/RecheckRemind/RecheckRemindDoctor/articleCenterPage/articleCenterPage'),
  },

  {
    path: '/articleDetail',
    name: 'article-detail2',
    meta: {
      title: "文章详情"
    },
    component: () =>
      import('@/views/RecheckRemind/RecheckRemindDoctor/articleDetailPage/articleDetailPage'),
  },
  {
    path: '/sendArticle',
    name: 'send-article',
    meta: {
      title: "发送文章"
    },
    component: () =>
      import('@/views/RecheckRemind/RecheckRemindDoctor/sendArticlePage/sendArticlePage'),
  },
  {
    path: '/searchPatient',
    name: 'search-patient',
    meta: {
      title: "搜索患者"
    },
    component: () =>
      import('@/views/RecheckRemind/RecheckRemindDoctor/searchPatientPage/searchPatientPage'),
  },
  {
    path: '/patientMain',
    name: 'patient-main',
    meta: {
      title: "患者主页"
    },
    component: () =>
      import('@/views/RecheckRemind/RecheckRemindDoctor/patientMainPage/patientMainPage'),
  },
  {
    path: '/recheckremindindex',
    name: 'recheck-remind-index',
    meta: {
      title: "智能随访用药"
    },
    component: () =>
      import('@/views/RecheckRemind/RecheckRemindDoctor/followupIndexPage/followupIndexPage'),
  },
  {
    path: '/verifyFollowupPlan',
    name: 'verify-followup-plan',
    meta: {
      title: "审核随访计划"
    },
    component: () =>
      import('@/views/RecheckRemind/RecheckRemindDoctor/verifyFollowupPlanPage/verifyFollowupPlanPage'),
  },
  {
    path: '/editFollowupPlan',
    name: 'edit-followup-plan',
    meta: {
      title: "编辑随访计划"
    },
    component: () =>
      import('@/views/RecheckRemind/RecheckRemindDoctor/editFollowupPlanPage/editFollowupPlanPage'),
  },
  {
    path: '/selectInspect',
    name: 'select-inspect2',
    meta: {
      title: "选择检查项目"
    },
    component: () =>
      import('@/views/RecheckRemind/RecheckRemindDoctor/selectInspectPage/selectInspectPage'),
  },
  {
    path: '/verifyFollowupRecord',
    name: 'verify-followup-record',
    meta: {
      title: "审核随访记录"
    },
    component: () =>
      import('@/views/RecheckRemind/RecheckRemindDoctor/verifyFollowupRecordPage/verifyFollowupRecordPage'),
  },
  {
    path: '/questionnaireList',
    name: 'questionnaire-list',
    meta: {
      title: "问卷列表"
    },
    component: () =>
      import('@/views/RecheckRemind/RecheckRemindDoctor/questionnaireListPage/questionnaireListPage'),
  },
  {
    path: '/recheckquestionnairedetail',
    name: 'recheck-questionnaire-detail',
    meta: {
      title: "问卷详情"
    },
    component: () =>
      import('@/views/RecheckRemind/RecheckRemindDoctor/questionnaireDetailPage/questionnaireDetailPage'),
  },
  {
    path: '/symptomList',
    name: 'symptom-list',
    meta: {
      title: "症状描述列表"
    },
    component: () =>
      import('@/views/RecheckRemind/RecheckRemindDoctor/symptomDescribeListPage/symptomDescribeListPage'),
  },
  {
    path: '/selectPatientTag',
    name: 'select-patient-tag',
    meta: {
      title: "选择患者类型"
    },
    component: () =>
      import('@/views/RecheckRemind/RecheckRemindDoctor/selectPatientTagPage/selectPatientTagPage'),
  },
  {
    path: '/followupRecordReadOnly',
    name: 'followup-record-read-only',
    meta: {
      title: "随访记录"
    },
    component: () =>
      import('@/views/RecheckRemind/RecheckRemindDoctor/followupRecordReadOnlyPage/followupRecordReadOnlyPage'),
  },
]

export default recheckRemindRouter

