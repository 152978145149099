// 报告解读Router
// Created by guoyijie on 2024-10-10.

const reportInterpretRouter = [
  {
    path: '/reportinterpretpatient',
    name: 'report-interpret-patient',
    component: () =>
      import('@/views/reportInterpret/reportInterpretPatient/reportInterpretPage'),
    meta: {
      title: '报告解读',
    }
  },
  
]

export default reportInterpretRouter